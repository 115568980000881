import { isUndefined, isNil, get, join, values } from 'lodash'
import * as A from 'constants/actions'
import T from 'types'
import { showNotification } from './ux'
import store from '../store'
import { ApiService } from 'services'
import tracker from 'utils/trackers'
import { didTrackIdentify } from 'actions/events'

export const storeOneTimeAuthToken = (oneTimeAuthToken: string | null) => ({
  payload: { oneTimeAuthToken },
  type: A.STORE_ONE_TIME_AUTH_TOKEN,
})

export const authToken = (username: string, password: string, cookiesEnabled: boolean) => ({
  meta: {
    onSuccess: (response: { data: string }) => {
      if (isNil(get(response.data, 'user'))) {
        store.dispatch(showNotification('info', 'Un code vous a été envoyé par SMS et par email'))
      } else {
        store.dispatch(authSignin({ username, password, token: ""}))
      }
    },
    onFailure: handleErrorResponse('Veuillez vérifier votre email et votre mot de passe.'),
  },
  promise: ApiService.createToken(username, password, cookiesEnabled),
  type: A.AUTH_TOKEN,
})

export const authSignin = (authParams: T.AuthParams) => ({
  meta: {
    onSuccess: () => {
      store.dispatch(showNotification('info', 'Connexion réussie'))
    },
    onFailure: handleErrorResponse("Mauvais code d'authentification, veuillez réessayer"),
  },

  promise: ApiService.createSession(authParams),
  type: A.AUTH_SIGNIN,
})

export const authSignout = () => ({
  meta: {
    onSuccess: () => {
      store.dispatch(showNotification('info', 'Déconnexion réussie'))
    },
    onFailure: () => {
      store.dispatch(showNotification('error', 'Une erreur est survenue'))
    },
  },
  promise: ApiService.destroySession(),
  type: A.AUTH_SIGNOUT,
})

export const setSignedOut = () => ({
  type: A.SET_SIGNED_OUT,
})

export const getAuthProfile = () => ({
  meta: {
    onSuccess: (response: any) => {
      if (store.getState().events.shouldIdentify) {
        const profile = response.data
        const traits = {
          birthday: profile.birthDate,
          email: profile.email,
          firstName: profile.firstName,
          lastName: profile.lastName,
          phone: profile.phoneNumber,
        }
        tracker.identifyUser(profile.userUuid, traits)
        store.dispatch(didTrackIdentify())
      }
    },
    onFailure: handleErrorResponse('error', { signOutErrors: [404] }),
  },
  promise: ApiService.getProfile(),
  type: A.AUTH_GET_PROFILE,
})

export const updateProfile = (data: any) => ({
  meta: {
    onSuccess: () => {
      store.dispatch(showNotification('success', 'Vos informations ont bien été sauvegardées'))
    },
    onFailure: () => {
      store.dispatch(showNotification('error', 'Une erreur est survenue'))
    },
  },
  promise: ApiService.updateProfile(data),
  type: A.UPDATE_PROFILE,
})

export const uploadMedicalDocument = (data: FormData) => ({
  meta: {
    onSuccess: () => {
      store.dispatch(showNotification('success', 'Votre document a bien été enregistré'))
    },
    onFailure: () => {
      store.dispatch(showNotification('error', 'Une erreur est survenue'))
    },
  },
  promise: ApiService.uploadDocuments(data),
  type: A.UPLOAD_MEDICAL_DOCUMENT,
})

export const deleteCreditCard = (creditCardId: number) => ({
  meta: {
    onSuccess: () => {
      store.dispatch(showNotification('success', 'Votre carte a bien été supprimée'))
    },
    onFailure: () => {
      store.dispatch(showNotification('error', 'Une erreur empêche la suppression de votre carte'))
    },
  },
  promise: ApiService.deleteCreditCard(creditCardId),
  type: A.DELETE_CREDIT_CARD,
})

export const updateAuthField = (field: string, value: any) => ({
  field,
  value,
  type: A.AUTH_UPDATE_FIELD,
})

const handleErrorResponse = (message: string, opts = {}) => (response: {
  data: any
  status: number
  statusText: string
}) => {
  const { data, status, statusText } = (response || {}) as {
    data: any
    status: number
    statusText: string
  }

  const { signOutErrors } = {
    signOutErrors: [] as number[],
    ...opts,
  }

  if (data) {
    console.error(data)
  }

  if (status >= 500) {
    store.dispatch(
      showNotification(
        'error',
        `
      Une erreur a été rencontrée au niveau du serveur : ${statusText}.
      Les administrateurs du service en ont été informés.
      Veuillez essayer à nouveau ultérieurement.
    `,
      ),
    )
    store.dispatch(setSignedOut())
  } else if (signOutErrors.indexOf(status) > -1) {
    store.dispatch(
      showNotification(
        'error',
        'Connexion impossible, l\'utilisateur n’a pas été trouvé.',
      ),
    )
    store.dispatch(setSignedOut())
  } else if (status === 401) {
    store.dispatch(
      showNotification(
        'error',
        'Votre session a expiré, veuillez-vous reconnecter.',
      ),
    )
    store.dispatch(setSignedOut())
  } else {
    const displayedMessage = !isUndefined(get(data, 'errors'))
      ? join(values(data.errors), '. ')
      : message
    store.dispatch(showNotification('error', displayedMessage))
  }
}
