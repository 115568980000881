import * as A from 'constants/actions'
import normalizedDataHandler from './helpers/normalizedDataHandler'
import { handle } from 'redux-pack'
import { without, concat, merge, get, isUndefined } from 'lodash'
import T from 'types'

type Action = {
  payload: object
  type: string
}

const initialState: T.Storefront = {
  loaded: false,
  loading: false,
  actionLoading: [],
  consultation: {},
  doctor: {},
  product: {},
  variant: {},
  image: {},
  order: {},
  cart: {},
  paymentMethod: {},
  optionValue: {},
  optionType: {},
  prescription: {},
  prescriptionItem: {},
  lineItem: {},
  subscriptionFrequencies: {},
  subscriptions: {},
  program: {}
}

export default (state = initialState, action: Action) => {
  const { payload } = action
  switch (action.type) {
    case A.GET_PRODUCTS:
    case A.GET_ORDER:
    case A.CREATE_DELIVERY:
    case A.PURCHASE_ORDER:
    case A.GET_PAYMENT_METHODS:
    case A.GET_ORDERS:
    case A.GET_SUBSCRIPTIONS:
    case A.CANCEL_SUBSCRIPTION:
    case A.ADD_TO_CART:
    case A.CREATE_CART:
    case A.GET_POST_CONSULTATION_DETAILS:
    case A.START_UNIFIED_CHECKOUT:
    case A.GET_LAST_FINISHED_CONSULTATION:
      return normalizedDataHandler(initialState)(state, action, {})
    case A.GET_CART:
    case A.BEGIN_CHECKOUT:
    case A.REMOVE_FROM_CART:
    case A.CHANGE_CART_ITEM_QUANTITY:
    case A.APPLY_COUPON:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: false,
          success: null,
          actionLoading: concat(prevState.actionLoading, [action.type]),
        }),
        finish: prevState => ({
          ...prevState,
          loading: false,
          loaded: true,
          actionLoading: without(prevState.actionLoading, action.type),
        }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => {
          const cartPayload = get(payload, 'cart')
          const lineItemPayload = get(payload, 'lineItem')

          if (isUndefined(lineItemPayload))
            return {
              ...prevState,
              cart: cartPayload,
            }

          return {
            ...prevState,
            cart: cartPayload,
            lineItem: lineItemPayload
          }
        }
      })
    default:
      return state
  }
}
