import SegmentTracker from './SegmentTracker'
import CompositeTracker from './CompositeTracker'

class CharlesTracker extends CompositeTracker {
  constructor() {
    super([
      new SegmentTracker(),
    ])
  }
}

export default new CharlesTracker()
