import { connect } from 'react-redux'
import {
  getAuthProfile,
  getConsultations,
  getPrescriptions,
  setFocusedPrescription,
  appendMessage,
  updateConsultation,
  storeLastFocusedLocation,
  getConsultationById,
  getMessageById,
  getLastFinishedConsultation,
} from 'actions'
import Home from 'page/Pages/Home'
import T from 'types'

const mapStateToProps = ({
  auth: { profile },
  auth: {
    profile: { doctor },
  },
  consultations: { collection },
  storefront,
  unifiedCheckout,
  prescriptions: {
    collectionPrescription,
    lastPrescription,
    lastPhytotherapyPrescription
  },
}: T.Store) => ({
  profile,
  doctor,
  collectionPrescription,
  lastPrescription,
  lastPhytotherapyPrescription,
  storefront,
  unifiedCheckout,
  consultations: collection,
})

const mapDispatchToProps = (dispatch: Function) => ({
  setCurrentProfile: () => dispatch(getAuthProfile()),
  getConsultations: () => dispatch(getConsultations()),
  setFocusedPrescription: (p: T.Prescription) => dispatch(setFocusedPrescription(p)),
  getPrescriptions: () => dispatch(getPrescriptions()),
  appendMessage: (message: T.Message) => dispatch(appendMessage(message)),
  getConsultationById: (consultationId: number) => dispatch(getConsultationById(consultationId)),
  getMessageById: (messageId: number) => dispatch(getMessageById(messageId)),
  updateConsultation: (consultation: T.Consultation) => dispatch(updateConsultation(consultation)),
  storeLastFocusedLocation: (location: any) => dispatch(storeLastFocusedLocation(location)),
  getLastFinishedConsultation: () => dispatch(getLastFinishedConsultation()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
