import React, { Component, createRef } from 'react'
import cx from 'classnames'
import { LoginForm, Navbar, Sidebar } from 'containers'
import './styles.sass'
import { withRouter, RouteComponentProps } from 'react-router'
import { shouldSignIn } from 'utils/router'

interface IProps extends RouteComponentProps{
  sidebarActive?: boolean
  children?: JSX.Element | JSX.Element[]
  signedIn: boolean
  isAcademy: boolean | undefined
  isConsultation: boolean | undefined
  title?: string
  subtitle?: string
  closeSidebar: () => void
  noHorizontalPadding?: boolean
  noVerticalPadding?: boolean
  unlimitedWidth? : true
}

class MainContainer extends Component<IProps> {
  unlistenHistory: any

  sidebarType() {
    const { isAcademy, isConsultation } = this.props
    if (isAcademy && !isConsultation) {
      return 'academy'
    }  if (isConsultation && !isAcademy) {
      return 'consultation'
    }  return 'full'
  }

  private rightPanelRef = createRef<HTMLDivElement>()

  componentDidMount() {
    this.unlistenHistory = this.props.history.listen(this.scrollToTop)
  }

  componentWillUnmount() {
    this.unlistenHistory()

  }

  scrollToTop = () => {
    if (this.rightPanelRef.current !== null) {
      this.rightPanelRef.current.scrollTo(0, 0)
    }
  }

  public render() {
    const { signedIn, title, subtitle, noHorizontalPadding, noVerticalPadding } = this.props
    const hasHeader = title || subtitle
    const contentHeader = hasHeader ? (
      <div className="mb-2">
        {title && <h1 className="h3">{title}</h1>}
        {subtitle && <div className="font-italic">{subtitle}</div>}
      </div>
    ) : null
    return !shouldSignIn(location, signedIn) ? (
      <div className="main-container">
        <Sidebar sidebarToDisplay={this.sidebarType()} />
        <div ref={this.rightPanelRef} className="right-panel">
          <Navbar />
          <div className={cx('page-container', { 'page-container-unlimited' : this.props.unlimitedWidth === true })}>
            <div
              className={cx('page-content', {
                'page-content-y-padding': !noVerticalPadding,
                'page-content-x-padding': !noHorizontalPadding,
              })}
            >
              {contentHeader}
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="login-page">
        <LoginForm />
      </div>
    )
  }
}

export default withRouter(MainContainer)
