import * as yup from 'yup'
import { I18n } from 'react-redux-i18n'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { isNil } from 'lodash'

const phoneRegExp = /^\+((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const phoneValidation = (value: any) => {
  const regexp = RegExp(phoneRegExp)
  return !isNil(value) && regexp.test(value) && isValidPhoneNumber(value)
}

const schema = yup.object({
  password: yup
    .string()
    .required('Vous devez saisir un mot de passe.')
    .min(8, 'Le mot de passe est trop court.'),
  passwordConfirmation: yup
    .string()
    .required('Vous devez saisir à nouveau votre mot de passe.')
    .oneOf([yup.ref('password')], 'Vos mots de passe ne correspondent pas.'),
  phoneNumber: yup
    .string()
    .trim()
    .test('valid-phone', 'Numéro de téléphone invalide', (value: any) => phoneValidation(value))
    .required('Ce champ est obligatoire'),
})

export default schema
