import {
  Academy,
  Account,
  Assistance, Checkout, DeliverPrescription, DownloadPrescription, BookingConsultation,
  MedicalDocuments,
  MedicalInfo,
  JoinCercle,
  OrdersHistory, Pharmacy, Phytotherapy, Prescription,
  Prescriptions, ProgramManager, PurchasePrescription, SleepCalendar,
} from 'containers'
import { Chat, Consultation } from 'page/Pages'
import { SecureChallengeStatus } from 'components'
import { matchPath } from 'react-router-dom'

export const createRoutes = () => {
  return [
    ['/account', Account],
    ['/orders', OrdersHistory],
    ['/medical-info', MedicalInfo],
    ['/prescriptions', Prescriptions],
    ['/documents', MedicalDocuments],
    ['/consultation/:id', Consultation],
    ['/assistance', Assistance],
    ['/new-consultation', BookingConsultation],
    ['/pharmacie/:id', Pharmacy],
    ['/ordonnance/:id', Prescription],
    ['/download-prescription/:id', DownloadPrescription],
    ['/deliver-prescription', DeliverPrescription],
    ['/purchase-prescription', PurchasePrescription],
    ['/messages', Chat],
    ['/phytotherapy/:id?', Phytotherapy],
    ['/checkout/:number', Checkout],
    ['/therapies-digitales', Academy],
    ['/programme-time', ProgramManager, { slug: 'time-program' }],
    ['/programme-master', ProgramManager, { slug: 'master-program' }],
    ['/programme-sommeil', ProgramManager, { slug: 'sommeil-program' }],
    ['/programme-vaginisme', ProgramManager, { slug: 'vaginisme-program' }],
    ['/agenda-sommeil', SleepCalendar],
    ['/challenge/:status', SecureChallengeStatus],
    ['/joincercle', JoinCercle],
  ]
}

export const shouldSignIn = (location: any , signedIn: any) => {
  const match = matchPath(location.hash, {
    path: ['#/challenge/:status'],
  })
  if (!signedIn && match === null) {
    return true
  }
}
