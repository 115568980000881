import { isUndefined, isEmpty, last } from 'lodash'
import { makeClient, Client } from '@spree/storefront-api-v2-sdk'
import { IProductsResult } from '@spree/storefront-api-v2-sdk/types/interfaces/Product'
import { IOrderResult, IOrdersResult } from '@spree/storefront-api-v2-sdk/types/interfaces/Order'
import { AddItem } from '@spree/storefront-api-v2-sdk/types/interfaces/endpoints/CartClass'
import { IPaymentMethodsResult } from '@spree/storefront-api-v2-sdk/types/interfaces/PaymentMethod'
import { NestedAttributes } from '@spree/storefront-api-v2-sdk/types/interfaces/endpoints/CheckoutClass'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'

import { decamelizeKeys } from 'humps'
import { CookiesHelper } from 'helpers'
import normalize from 'json-api-normalizer'
import {
  createPaymentRequestPayload,
} from 'utils/helpers'
import T from 'types'

class StorefrontApiService {
  client: Client

  constructor() {
    this.client = makeClient({
      host: process.env.REACT_APP_API_BASE_URL,
    })
  }

  getProducts(productIds: number[]) {
    return this.client.products
      .list({
        filter: { ids: productIds.map((id) => id.toString()).join(',') },
        include:
          'option_types.option_values,images,variants_including_master,variants_including_master.images,variants_including_master.option_values,subscription_frequencies',
        // include: 'images,variants,variants_including_master',
        per_page: 100,
      })
      .then((productsResult: IProductsResult) => {
        const result = normalize(productsResult.success())
        return result
      })
  }

  createCart() {
    const bearerToken = CookiesHelper.getPureOauthAccessToken()

    return this.client.cart
      .create(
        { bearerToken },
      )
      .then((orderResult: IOrderResult) => {
        if (orderResult.isSuccess()) {
          return Promise.resolve(normalize(orderResult.success()))
        } else {
          return Promise.reject(orderResult.fail())
        }
      })
  }

  addToCart(variant: T.Variant) {
    const bearerToken = CookiesHelper.getPureOauthAccessToken()

    return this.client.cart
      .addItem(
        { bearerToken },
        {
          variant_id: variant.id.toString(),
          quantity: 1,
          include: 'line_items'
        } as AddItem,
      )
      .then((orderResult: IOrderResult) => {
        if (orderResult.isSuccess()) {
          return Promise.resolve(normalize(orderResult.success()))
        } else {
          return Promise.reject(orderResult.fail())
        }
      })
  }

  setQuantity(lineItemId: number, quantity: number) {
    const bearerToken = CookiesHelper.getPureOauthAccessToken()

    return this.client.cart
      .setQuantity(
        { bearerToken },
        {
          line_item_id: lineItemId.toString(),
          quantity: quantity,
          include: 'line_items'
        },
      )
      .then((orderResult: IOrderResult) => {
        if (orderResult.isSuccess()) {
          return Promise.resolve(normalize(orderResult.success()))
        } else {
          return Promise.reject(orderResult.fail())
        }
      })
  }

  removeFromCart(itemId: number) {
    const bearerToken = CookiesHelper.getPureOauthAccessToken()

    return this.client.cart
      .removeItem(
        { bearerToken },
        itemId.toString()
      )
      .then((orderResult: IOrderResult) => {
        if (orderResult.isSuccess()) {
          return this.getCart()
        } else {
          return Promise.reject(orderResult.fail())
        }
      })
  }

  getCart() {
    const bearerToken = CookiesHelper.getPureOauthAccessToken()

    return this.client.cart.show(
      { bearerToken },
      { include: 'line_items' }
    ).then((orderResult: IOrderResult) => {
      if (orderResult.isSuccess())
        return Promise.resolve(normalize(orderResult.success()))
      else
        return this.createCart()
    })
  }

  beginCheckout(choices: T.CheckoutVariantChoice[]) {
    const bearerToken = CookiesHelper.getPureOauthAccessToken()
    return this.client.cart.create({ bearerToken }).then((orderResult: IOrderResult) => {
      const orderToken = orderResult.success().data.attributes.number
      return this.client.cart.emptyCart({ bearerToken }).then((orderResult: IOrderResult) => {
        if (!isEmpty(choices)) {
          return Promise.all(
            choices.map((choice: T.CheckoutVariantChoice) =>
              this.client.cart.addItem(
                { bearerToken },
                {
                  variant_id: choice.variant.id.toString(),
                  quantity: choice.quantity,
                  options: choice.options,
                  include: 'line_items',
                } as AddItem,
              ),
            ),
          ).then((responses: IOrderResult[]) => {
            const lastResponse: IOrderResult | undefined = last(responses)
            if (lastResponse && lastResponse.isSuccess()) {
              const cart = normalize(lastResponse.success())
              return Promise.resolve(cart)
            } {
              const failData = lastResponse ? lastResponse.fail() : {}
              return Promise.reject(failData)
            }
          })
        }
        else {
          const cart = normalize(orderResult.success())
          return Promise.resolve(cart)
        }
      }
      )
    })
  }

  // Deprecated
  purchaseWithExistingCard(token: IToken, cardId: number) {
    const bearerToken = CookiesHelper.getPureOauthAccessToken()
    const orderToken = isEmpty(token) ? { bearerToken } : token
    // force-casting as storefront didn't declare existing_card parmas in NestedAttributes ... ?
    const nestedAttributes: NestedAttributes = {
      order: {
        existing_card: cardId,
      },
    } as NestedAttributes
    return this.completeOrder(orderToken, nestedAttributes)
  }

  purchase(token: IToken, data: T.PaymentPayload) {
    const bearerToken = CookiesHelper.getPureOauthAccessToken()
    const orderToken = isEmpty(token) ? { bearerToken } : token
    const paymentRequestPayload: T.PaymentRequestPayload = createPaymentRequestPayload(data)
    const nestedAttributes = decamelizeKeys(paymentRequestPayload as Object)
    return this.completeOrder(orderToken, nestedAttributes)
  }

  completeOrder(token: IToken, params: NestedAttributes): Promise<IOrderResult> {
    return this.client.checkout.orderUpdate(token, params).then(
      (orderResult: IOrderResult) => {
        if (orderResult.isSuccess()) {
          return this.client.checkout.complete(token).then((orderResult: IOrderResult) => {
            if (orderResult.isSuccess()) {
              return Promise.resolve(normalize(orderResult.success()))
            }
            return Promise.reject(orderResult.fail())
          })
        }
        else {
          return Promise.reject(orderResult.fail())
        }
      }
    )
  }

  getOrder(order: T.OrderV1) {
    return this.client.checkout
      .orderUpdate({ orderToken: order.token }, {})
      .then((orderResult: IOrderResult) => {
        if (orderResult.isSuccess()) {
          return normalize(orderResult.success())
        }
        return this.getCompletedOrder(order)

      })
  }

  getCompletedOrders() {
    const bearerToken = CookiesHelper.getPureOauthAccessToken()
    return this.client.account
      .completedOrdersList({ bearerToken }, { per_page: 1000 })
      .then((ordersResult: IOrdersResult) => normalize(ordersResult.success()))
  }

  getCompletedOrder(order: T.OrderV1) {
    const bearerToken = CookiesHelper.getPureOauthAccessToken()
    return this.client.account
      .completedOrder({ bearerToken }, order.number)
      .then((orderResult: IOrderResult) => normalize(orderResult.success()))
  }

  getPaymentMethods(orderToken?: string) {
    const bearerToken = CookiesHelper.getPureOauthAccessToken()
    const token: IToken = isUndefined(orderToken) ? { bearerToken } : { orderToken }
    return this.client.checkout
      .paymentMethods(token)
      .then((paymentMethods: IPaymentMethodsResult) => normalize(paymentMethods.success()))
  }

  applyCoupon(coupon: string, orderToken: string) {
    return this.client.cart
      .applyCouponCode(
        { orderToken },
        {
          coupon_code: coupon,
        },
      )
      .then((orderResult: IOrderResult) => {
        if (orderResult.isSuccess()) {
          return Promise.resolve(normalize(orderResult.success()))
        }
        return Promise.reject(orderResult.fail())
      })
  }

  changeCartToVariant(variantId: number, orderToken: string) {
    return this.client.cart
      .emptyCart({ orderToken })
      .then((orderResult: IOrderResult) =>
        this.client.cart.addItem(
          { orderToken },
          {
            variant_id: variantId.toString(),
            quantity: 1,
            include: 'line_items',
          },
        ),
      )
      .then((response: IOrderResult) => {
        if (response.isSuccess()) {
          const cart = normalize(response.success())
          return Promise.resolve(cart)
        } {
          const failData = response.fail()
          return Promise.reject(failData)
        }
      })
  }

}

export default new StorefrontApiService()
