import React from 'react'
import Media from 'react-media'
import { isEmpty, chunk, get } from 'lodash'
import { icons } from 'assets/icons'
import SvgIcon from 'components/SvgIcon'
import cx from 'classnames'
import T from 'types'
import './styles.sass'

type IProps = typeof ResponsiveTabs.defaultProps & {
  tabs: T.UI.IResponsiveTabsItem[]
  chunkSize?: number
  selected?: number
}

interface IState {
  selectedTab: number
  showedChunk: number
}
class ResponsiveTabs extends React.Component<IProps, IState> {

  static defaultProps = {
    chunkSize: 5,
  }
  constructor(props: IProps) {
    super(props)
    this.state = {
      selectedTab: 0,
      showedChunk: 0
    }
  }

  componentDidMount(): void {
    const { selected } = this.props
    if (selected) {
      this.setState({ selectedTab: selected })
      if (selected >= 4) {
        this.setState({ showedChunk: Math.floor((selected) / 4) })
      }
    }
  }

  render() {
    const { tabs } = this.props
    return isEmpty(tabs) ? null : (
      <div className="responsive-tabs">
        <Media
          query="(max-width: 1245px)"
          render={() => this.renderMobile()}
        />
        <Media
          query="(min-width: 1246px)"
          render={() => this.renderDesktop()}
        />
      </div>
    )
  }

  renderMobile = () => {
    const { tabs } = this.props
    const { selectedTab } = this.state

    return (
      <div className="responsive-tabs-mobile">
        {tabs.map((tab: T.UI.IResponsiveTabsItem, index: number) => (
          <div
            className={cx("responsive-tabs-item", { disabled: !tab.isActive })}
            key={`responive-tabs-mobile-${index}`}
          >
            <div
              className={cx("responsive-tabs-item-title", { selected: index === selectedTab })}
              onClick={() => tab.isActive && this.selectTab(index, true)}
            >
              {tab.title}

              {tab.isActive && <div className="ml-auto expand-button" />}
            </div>
            {index === selectedTab && (<div className={cx("responsive-tabs-content")}>
              {tab.content()}
            </div>)}
          </div>
        ))}
      </div>
    )
  }

  renderDesktop = () => {
    const { tabs, chunkSize } = this.props
    const { selectedTab, showedChunk } = this.state
    const tabChunks = chunk(tabs, chunkSize)
    const showPrev = showedChunk > 0
    const showNext = tabChunks.length > 1 && (showedChunk < tabChunks.length - 1)
    const indexOffset = chunkSize * showedChunk
    const absoluteIndex = (chunkIndex: number) => (indexOffset + chunkIndex)
    const selectedTabContent = tabs[selectedTab]
    const showedTabs = tabChunks[showedChunk]
    return (
      <div className="responsive-tabs-desktop">
        <div className="responsive-tabs-titles">
          <div
            className="arrow-container mx-3 d-flex align-items-center"
            onClick={() => this.selectChunk(showedChunk - 1)}
          >
            {showPrev && <SvgIcon icon={get(icons, 'shapes.back')} classname="responsive-tabs-titles-arrow" />}
          </div>
          <div className="responsive-tabs-titles-container d-flex">
            {showedTabs.map((tab: T.UI.IResponsiveTabsItem, chunkIndex: number) => (
              <div
                className={cx("responsive-tabs-item-title", { selected: absoluteIndex(chunkIndex) === selectedTab, disabled: !tab.isActive})}
                onClick={() => tab.isActive && this.selectTab(absoluteIndex(chunkIndex), false)}
                key={`responsive-tabs-desktop-${absoluteIndex(chunkIndex)}`}
              >
                {tab.title}
              </div>
            ))}
          </div>
          <div
            className="arrow-container mx-3 d-flex align-items-center"
            onClick={() => this.selectChunk(showedChunk + 1)}
          >
            {showNext && <SvgIcon icon={get(icons, 'shapes.back')} classname="responsive-tabs-titles-arrow responsive-tabs-titles-arrow-back" />}
          </div>
        </div>
        {selectedTabContent && (
            <div className={cx("responsive-tabs-content")}>
              {selectedTabContent.content()}
            </div>
        )}
      </div>
    )
  }

  selectTab = (newIndex: number, hideIfSame: boolean) => {
    const { selectedTab } = this.state
    const nextSelectedTab = (selectedTab === newIndex && hideIfSame) ? -1 : newIndex
    this.setState({ selectedTab: nextSelectedTab })
  }

  selectChunk = (newIndex: number) => {
    const { chunkSize } = this.props
    this.setState({ showedChunk: newIndex, selectedTab: (newIndex * chunkSize) })
  }
}

export default ResponsiveTabs
