import React, { Component, Fragment } from 'react'
import { get, isUndefined, isNil } from 'lodash'
import moment from 'constants/moment'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { I18n } from 'react-redux-i18n'
import { Button, Loader } from 'components'
import * as SC from 'utils/models/schemas/constants'
import schema from 'utils/models/schemas/createPassword'
import { Formik } from 'formik'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'

interface IProps {
  patientAttributes: any
  invitationToken: string | null
  onSubmit: (data: any) => void
}

class CreatePasswordForm extends Component<IProps> {
  public handleGlobalSubmit = (data: any) => {
    const { onSubmit } = this.props
    onSubmit({
      ...data,
      invitationToken: this.props.invitationToken,
    })
  }

  public render() {
    const { patientAttributes, invitationToken } = this.props

    return isNil(invitationToken) ? (
      <Loader />
    ) :  (
      <div className="form-edition">
        <Formik
          validationSchema={schema}
          onSubmit={this.handleGlobalSubmit}
          validateOnChange={true}
          validateOnBlur={false}
          initialValues={{
            password: '',
            passwordConfirmation: '',
            phoneNumber: get(patientAttributes, 'phoneNumber', '') || '',
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className="onboarding-container-bloc">
                <Fragment>
                  <div className="onboarding-small-inner-container">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>{I18n.t(`onboarding.personnalData.passwordCreation`)}</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.password}
                        placeholder={I18n.t(`onboarding.personnalDataPlaceholders.passwordCreation`)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="validationFormik02">
                      <Form.Label>{I18n.t(`onboarding.personnalData.passwordConfirmation`)}</Form.Label>
                      <Form.Control
                        type="password"
                        name="passwordConfirmation"
                        value={values.passwordConfirmation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.passwordConfirmation}
                        placeholder={I18n.t(`onboarding.personnalDataPlaceholders.passwordConfirmation`)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.passwordConfirmation}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="validationFormik04">
                      <Form.Label>
                        {I18n.t(`onboarding.personnalData.phoneNumber`)}
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="why-phone-is-required">{I18n.t(`onboarding.personnalData.tooltips.phoneNumber`)}</Tooltip>}
                        >
                          <span className="tooltip-info-icon ml-1">i</span>
                        </OverlayTrigger>
                      </Form.Label>
                      <PhoneInput
                        placeholder={I18n.t(`onboarding.personnalDataPlaceholders.phoneNumber`)}
                        numberInputProps={{ className: 'form-control' }}
                        value={values.phoneNumber}
                        onChange={(value: string) => {
                          setFieldValue('phoneNumber', value)
                        }}
                        onBlur={handleBlur}
                        defaultCountry="FR"
                        countryOptionsOrder={[
                          'FR',
                          'GP',
                          'GF',
                          'MQ',
                          'YT',
                          'NC',
                          'PF',
                          'RE',
                          'BL',
                          'MF',
                          'PM',
                          '|',
                          '...',
                        ]}
                      />
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.phoneNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Button classname="submit-button onb-next-btn" type="submit">
                      {"Créer mon mot de passe"}
                    </Button>
                  </div>
                </Fragment>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }
}

export default CreatePasswordForm;
