export const PRESCRIPTION_TYPE_ERECTION_DRUGS = 'erection_drugs'
export const PRESCRIPTION_TYPE_HAIRLOSS_DRUGS = 'hairloss_drugs'
export const PRESCRIPTION_TYPE_WEIGHT_DRUGS = 'weight_drugs'
export const PRESCRIPTION_TYPE_ANALYSIS = 'analysis'
export const PRESCRIPTION_TYPE_PHYTOTHERAPY = 'phytotherapy'
export const PRESCRIPTION_TYPE_OTHER = 'other'

export const SAXENDA_MOLECULE = 'saxenda'
export const WEGOVY_MOLECULE = 'wegovy'
export const MOUNJARO_MOLECULE = 'mounjaro'

export const SAXENDA_PRICE = 220
export const WEGOVY_PRICE = 320
export const MOUNJARO_PRICE = 'TBD'
