import * as A from 'constants/actions'
import { StorefrontApiService, ApiService } from 'services'
import { get, isUndefined, find, isEmpty, first, values, isNil, map, valuesIn } from 'lodash'
import { showNotification, startSecureChallenge } from './ux'
import store from '../store'
import build from 'redux-object'
import T from 'types'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import SFErrorTypes from '@spree/storefront-api-v2-sdk/types/errors'
import { errors } from '@spree/storefront-api-v2-sdk'
const { BasicSpreeError, ExpandedSpreeError } = errors

export const getProducts = (productIds: Array<number> | undefined = []) => ({
  type: A.GET_PRODUCTS,
  promise: StorefrontApiService.getProducts(productIds),
})

export const getCart = () => ({
  type: A.GET_CART,
  promise: StorefrontApiService.getCart(),
})

export const createCart = () => ({
  type: A.CREATE_CART,
  promise: StorefrontApiService.createCart(),
  meta: {
    onSuccess: (response: any) => {
      // TODO dimitri: Maybe set a global var?
    },
    onFailure: (response: { errors: { base: string[] } }) => {
      const messages = get(response, 'error')

      if (isEmpty([messages])) {
        store.dispatch(showNotification('error', 'Une erreur est survenue'))
      } else {
        store.dispatch(showNotification('error', [messages].join(' ')))
      }
    },
  },
})

export const removeFromCart = (itemId: number) => ({
  type: A.REMOVE_FROM_CART,
  promise: StorefrontApiService.removeFromCart(itemId),
})

export const setVariantQuantity = (lineItemId: number, quantity: number) => ({
  type: A.CHANGE_CART_ITEM_QUANTITY,
  promise: StorefrontApiService.setQuantity(lineItemId, quantity),
})

export const addToCart = (variant: T.Variant, successCallback: Function | undefined) => ({
  type: A.ADD_TO_CART,
  promise: StorefrontApiService.addToCart(variant),
  meta: {
    onSuccess: (response: any) => {
      // store.dispatch(showNotification('success', 'Added to cart'))

      if (successCallback)
        successCallback(response)
    },
    onFailure: (response: { errors: { base: string[] } }) => {
      const messages = get(response, 'error')

      if (isEmpty([messages])) {
        store.dispatch(showNotification('error', 'Une erreur est survenue'))
      } else {
        store.dispatch(showNotification('error', [messages].join(' ')))
      }
    },
  },
})

export const beginCheckout = (variants: T.CheckoutVariantChoice[], successCallback: Function) => ({
  type: A.BEGIN_CHECKOUT,
  promise: StorefrontApiService.beginCheckout(variants),
  meta: {
    onSuccess: (response: any) => successCallback(response),
  },
})

export const createDelivery = (data: T.Forms.ShippingAddress, successCallback: Function) => ({
  type: A.CREATE_DELIVERY,
  promise: ApiService.createDelivery(data),
  meta: {
    onSuccess: (response: any) => successCallback(response),
    onFailure: (response: { errors: { base: string[] } }) => {
      const messages = get(response, 'error')

      if (isEmpty([messages])) {
        store.dispatch(showNotification('error', 'Une erreur est survenue'))
      } else {
        store.dispatch(showNotification('error', [messages].join(' ')))
      }
    },
  },
})

export const getOrderFromNumber = (orderNumber: string) => {
  const orders = build(store.getState().storefront, 'cart') || []
  const order = find(orders, (order: any) => order.number === orderNumber)
  if (order) {
    return {
      type: A.GET_ORDER,
      promise: StorefrontApiService.getOrder(order),
    }
  }
}

export const getCompletedOrders = () => ({
  type: A.GET_ORDERS,
  promise: StorefrontApiService.getCompletedOrders(),
})

export const purchase = (
  token: IToken,
  data: T.PaymentPayload,
  onSuccess: Function,
) => ({
  meta: {
    onSuccess: (response: { data: string }) => {
      const cart = first(values(get(response, 'cart')))
      const redirectUrl = get(get(cart, 'attributes'), 'gatewaySecureModeRedirectUrl')
      const paymentMethods = get(data, 'paymentMethod')

      if (isNil(redirectUrl)) {
        store.dispatch(showNotification('success', 'Paiement effectué avec succès'))
      } else {
        if (paymentMethods && 'paypal' in paymentMethods)
          store.dispatch(showNotification('info', 'Vous allez être redirigé vers l’interface Paypal'))
        else
          store.dispatch(showNotification('info', 'Vous allez être redirigé vers l’interface 3DSecure de votre banque'))
      }

      onSuccess(redirectUrl)
    },
    onFailure: (response: SFErrorTypes.SpreeSDKError) => {
      store.dispatch(showNotification('error', getMessageFromSDKError(response)))
    }
  },
  type: A.PURCHASE_ORDER,
  promise: StorefrontApiService.purchase(
    token,
    data,
  ),
})

// TODO, delete this method and move logic to puchase after total migration to 3ds challenge
export const purchaseWith3dsChallenge = (
  token: IToken,
  data: T.PaymentPayload,
  onSuccess: Function,
) => ({
  meta: {
    onSuccess: (response: { data: string }) => {
      const cart = first(values(get(response, 'cart')))
      const gatewaySecureModeRedirectUrl = get(get(cart, 'attributes'), 'gatewaySecureModeRedirectUrl')
      const paymentMethods = get(data, 'paymentMethod')

      if (isNil(gatewaySecureModeRedirectUrl)) {
        store.dispatch(showNotification('success', 'Paiement effectué avec succès'))
        onSuccess()
      } else {
        if (paymentMethods && 'paypal' in paymentMethods)
          store.dispatch(showNotification('info', 'Vous allez être redirigé vers l’interface Paypal'))
        else
          store.dispatch(showNotification('info', 'Vous allez être redirigé vers l’interface 3DSecure de votre banque'))

        const successActionCallback = () => {
          onSuccess()
          // This should be refactored, we should not have to throw an dummy action
          return ({ type: "DUMMY_USELESS_ACTION" })
        }
        const errorActionCallback = () => {
          // This should be refactored, we should not have to throw an dummy action
          return ({ type: "DUMMY_USELESS_ACTION" })
        }
        store.dispatch(startSecureChallenge({
          gatewaySecureModeRedirectUrl,
          successAction: successActionCallback,
          failureAction: errorActionCallback
        }))
      }
    },
    onFailure: (response: SFErrorTypes.SpreeSDKError) => {
      store.dispatch(showNotification('error', getMessageFromSDKError(response)))
    }
  },
  type: A.PURCHASE_ORDER,
  promise: StorefrontApiService.purchase(
    token,
    data,
  ),
})

export const getPaymentMethods = (orderToken?: string) => ({
  type: A.GET_PAYMENT_METHODS,
  promise: StorefrontApiService.getPaymentMethods(orderToken),
})

export const getSubscriptions = () => ({
  type: A.GET_SUBSCRIPTIONS,
  promise: ApiService.getSubscriptions(),
})

export const cancelSubscription = (subscriptionId: number) => ({
  type: A.CANCEL_SUBSCRIPTION,
  promise: ApiService.cancelSubscription(subscriptionId),
})


const getMessageFromSDKError = (error: SFErrorTypes.SpreeSDKError): string => {
  if(error instanceof ExpandedSpreeError){
    const spreeError: SFErrorTypes.ExpandedSpreeError = error
    const messages = map(Object.values(spreeError.errors), (subHash: any) => Object.values(subHash))
    return messages.join(", ")
  }
  else if(error instanceof BasicSpreeError){
    return (error as SFErrorTypes.BasicSpreeError).summary
  }
  return 'Une erreur est survenue'
}
