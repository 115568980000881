import { connect } from 'react-redux'
import {
  getProducts,
  beginCheckout,
  getPrescriptionPdf,
  getPrescriptions,
} from 'actions'
import Phytotherapy from 'page/Pages/Phytotherapy'
import T from 'types'

const mapStateToProps = ({ auth: { profile }, storefront, prescriptions: { collectionPrescription } }: T.Store) => ({
  profile,
  storefront,
  collectionPrescription,
})

const mapDispatchToProps = (dispatch: Function) => ({
  getProducts: (productIds: Array<number> | undefined) => dispatch(getProducts(productIds)),
  getPrescriptions: () => dispatch(getPrescriptions()),
  beginCheckout: (variants: T.CheckoutVariantChoice[], onSuccess: Function) =>
    dispatch(beginCheckout(variants, onSuccess)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Phytotherapy)
