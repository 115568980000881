import React, { Fragment } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { isNil, isUndefined, first, capitalize, filter, sortBy, isEmpty, orderBy } from 'lodash'
import { camelizeKeys } from 'humps'
import build from 'redux-object'
import { ChatboxV2, MainContainer, TherapyPrescriptionBlock } from 'containers'
import cx from 'classnames'
import { filterPrescriptionByType } from 'utils/helperComponents/lastConsultationHelpers'
import { getIcon } from 'assets/icons'
import pluralize from 'pluralize'
import {
  Teleconsultation,
  SvgIcon,
  PrescriptionCarousel,
  ProgramRecommendationCarousel,
  TreatmentPlan,
  Loader,
} from 'components'
import { cableFactory } from 'helpers/actioncable-helper'
import T from 'types'
import { PRESCRIPTION_TYPE_PHYTOTHERAPY, PRESCRIPTION_TYPE_ERECTION_DRUGS, PRESCRIPTION_TYPE_HAIRLOSS_DRUGS, PRESCRIPTION_TYPE_WEIGHT_DRUGS } from 'constants/prescriptions'
import { IS_CHARLES_PLATFORM } from 'constants/config'
import moment from 'constants/moment'
import './styles.sass'

let messagesRoom: any
let consultationsRoom: any

interface IProps extends RouteComponentProps<any> {
  profile: T.Profile
  consultations: T.Consultation[]
  collectionPrescription: T.Prescription[]
  lastPrescription: T.Prescription
  lastPhytotherapyPrescription: T.Prescription
  storefront: T.Storefront
  unifiedCheckout: T.UnifiedCheckoutStore
  doctor?: T.LightDoctor
  getProducts: () => void
  getConsultations: () => void
  updateConsultation: (consultation: T.Consultation) => void
  appendMessage: (message: T.Message) => void
  setFocusedPrescription: (p: T.Prescription) => void
  getPrescriptions: () => void
  storeLastFocusedLocation: (location: any) => void
  getConsultationById: (consultationId: number) => Promise<any>
  getMessageById: (messageId: number) => Promise<any>
  getLastFinishedConsultation: () => void
}

class Home extends React.Component<IProps> {
  cable: any

  constructor(props: IProps) {
    super(props)

    this.cable = cableFactory()
  }

  private initSockets() {
    const { appendMessage, updateConsultation, getPrescriptions, getConsultationById, getMessageById, getLastFinishedConsultation } = this.props

    messagesRoom = this.cable.subscriptions.create(
      { channel: 'MessagesChannel' },
      {
        connected: () => null,
        create: () => null,
        received: (message_id: number) => {
          getMessageById(message_id).then((data) => {
            appendMessage(data.payload.message)
          })
        },
      },
    )

    consultationsRoom = this.cable.subscriptions.create(
      { channel: 'ConsultationsChannel' },
      {
        connected: () => null,
        create: () => null,
        received: (payload: any) => {
          if (payload.event == 'update_consultation') {
            getConsultationById(payload.consultation_id).then((data) => {
              updateConsultation(camelizeKeys(data.payload.consultation) as T.Consultation)
            })
            getLastFinishedConsultation()
          }
          getPrescriptions()
        },
      },
    )
  }

  public componentDidMount() {
    const { getConsultations, getPrescriptions, getProducts, getLastFinishedConsultation } = this.props

    getConsultations()
    getPrescriptions()
    getLastFinishedConsultation()
    this.initSockets()
  }

  public componentWillUnmount() {
    if (messagesRoom) {
      this.cable.subscriptions.remove(messagesRoom)
    }
    if (consultationsRoom) {
      this.cable.subscriptions.remove(consultationsRoom)
    }
  }

  private handleErectionPrescriptionClick(prescription: T.Prescription) {
    const { setFocusedPrescription, history } = this.props
    if (!prescription.canBeReleased && moment().isAfter(moment(prescription.expiredAt))) {
      history.push('/new-consultation/specialite')
    } else {
      setFocusedPrescription(prescription)
      history.push(`/ordonnance/${prescription.id}`)
    }
  }

  private renderTherapyPrescription() {
    const { consultations, lastPrescription, profile, storeLastFocusedLocation, history } = this.props
    const { activeConsultationPrescriptions } = profile
    const lastConsultation = first(consultations)

    if (!IS_CHARLES_PLATFORM) return

    if (isUndefined(lastConsultation) || !activeConsultationPrescriptions || isEmpty(activeConsultationPrescriptions)) {
      return <Fragment />
    }

    return <TherapyPrescriptionBlock activePrescriptionsKind={activeConsultationPrescriptions} />
  }

  private handlePhytoClick(prescription: T.Prescription) {
    const { history } = this.props
    history.push(`/phytotherapy/${prescription.id}`)
  }

  private renderTreatments() {
    const { collectionPrescription } = this.props

    const shouldShowPrescription = (prescription: T.Prescription) =>
      prescription.overTheCounter || moment(prescription.expiredAt).diff(moment(), 'years', true) > -2

    // only overTheCounter prescription or expired since less than 2 years
    const showableDrugsPrescriptions = filterPrescriptionByType(
      collectionPrescription,
      [PRESCRIPTION_TYPE_ERECTION_DRUGS, PRESCRIPTION_TYPE_HAIRLOSS_DRUGS, PRESCRIPTION_TYPE_WEIGHT_DRUGS],
      true)
      .filter(obj => shouldShowPrescription(obj))

    // show "releasable" prescriptions first and sort by expiry date
    const drugsPrescriptions = orderBy(showableDrugsPrescriptions, [
      (prescription) => prescription.canBeReleased,
      (prescription) => moment(prescription.expiredAt).diff(moment()),
    ], ['desc', 'desc']).slice(0, 4)

    const validDrugsPrescriptions = filter(drugsPrescriptions, (a) => (a.overTheCounter || moment(a.expiredAt).diff(moment()) > 0))

    const phytoPrescriptions = filterPrescriptionByType(
      collectionPrescription,
      [PRESCRIPTION_TYPE_PHYTOTHERAPY],
      false,
    )
    const validPhytoPrescriptions = filter(phytoPrescriptions, (a) => moment(a.expiredAt).diff(moment()) > 0)

    const displayOneLine = (phytoPrescriptions.length + drugsPrescriptions.length) <= 2

    return (
      <div className={cx('treatments-container', { 'd-flex flex-column flex-md-row': displayOneLine })}>
        <PrescriptionCarousel
          prescriptions={drugsPrescriptions}
          title="Mes traitements"
          rightTitleCta={
            <div className="d-block mt-2">
              <Link className="carousel-cta" to="/prescriptions">
                <SvgIcon classname="icon mr-1" icon={getIcon('shapes.roundedInfo')} />
                Vous avez {pluralize('ordonnance', validDrugsPrescriptions.length, true)} {pluralize('valide', validDrugsPrescriptions.length)}
              </Link>
            </div>
          }
          icon="clinic.pill"
          onPrescriptionClick={(p: T.Prescription) => this.handleErectionPrescriptionClick(p)}
        />
        <PrescriptionCarousel
          prescriptions={phytoPrescriptions}
          title="Mes phytothérapies"
          rightTitleCta={
            <div className="d-block mt-2">
              <Link className="carousel-cta" to="/prescriptions">
                <SvgIcon classname="icon mr-1" icon={getIcon('shapes.roundedInfo')} />
                Vous avez {pluralize('ordonnance', validPhytoPrescriptions.length, true)} {pluralize('valide', validPhytoPrescriptions.length)}
              </Link>
            </div>
          }
          icon="blocks.phyto"
          onPrescriptionClick={(p: T.Prescription) => this.handlePhytoClick(p)}
        />
      </div>
    )
  }

  private renderOngoingChats(consultations: T.Consultation[]) {
    if (isEmpty(consultations)) {
      return null
    }

    return <div className="ongoing-chats-block">
      <ChatboxV2 consultations={consultations} />
    </div>
  }

  private renderTreatmentPlan() {
    const { storefront, unifiedCheckout, collectionPrescription, profile, history, setFocusedPrescription } = this.props

    if(isNil(unifiedCheckout.lastFinishedConsultationId)){
      return null
    }
    const lastFinishedConsultation: T.ConsultationV2 = build(storefront, 'consultation', unifiedCheckout.lastFinishedConsultationId)
    const recommendedPrograms = lastFinishedConsultation.programs

    // sort by expiry date
    const drugsPrescriptions = lastFinishedConsultation.drugsPrescriptions
      .sort((a, b) => moment(b.expiredAt).diff(moment()) - moment(a.expiredAt).diff(moment()))

    const phytoPrescriptions = lastFinishedConsultation.phytoPrescriptions
      .sort((a, b) => moment(b.expiredAt).diff(moment()) - moment(a.expiredAt).diff(moment()))

    return (
      <TreatmentPlan
        drugsPrescriptions={drugsPrescriptions}
        phytoPrescriptions={phytoPrescriptions}
        programs={recommendedPrograms}
        onDrugsClick={(p: T.Prescription) => this.handleErectionPrescriptionClick(p)}
        onPhytoClick={(p: T.Prescription) => this.handlePhytoClick(p)}
        collectionPrescription={collectionPrescription}
        profile={profile}
        setFocusedPrescription={setFocusedPrescription}
        history={history}
      />
    )
  }

  private renderTeleconsultations() {
    const { consultations, profile } = this.props

    const chatConsultations = sortBy(
      filter(consultations, (c) => c.communicationChannel == 'chat' && (c.state == 'finished' || c.state == 'started')),
      (c) => c.appointmentAt
    ).reverse()

    const visioConsultations = filter(consultations, (c) => c.communicationChannel != 'chat')
    const upcomingConsultations = sortBy(filter(visioConsultations, (c) => c.state == 'confirmed' || c.state == 'started'), (c) => c.appointmentAt)

    const upcomingConsultationsEl = upcomingConsultations.map((c) => (
      <Teleconsultation
        title={'Ma consultation à venir'}
        icon={'clinic.largeSpecialist'}
        consultation={c}
        summaryOnly={false}
        profile={profile}
      />
    ))

    return (
      <Fragment>
        {upcomingConsultationsEl}
        {this.renderOngoingChats(chatConsultations)}
      </Fragment>
    )
  }

  private renderPastConsulation() {
    const { consultations } = this.props

    const finishedConsultations = filter(consultations, (c) => c.state == 'finished' && c.communicationChannel != 'chat')

    const lastConsultation = first(finishedConsultations)

    if (!lastConsultation) {
      return null
    }

    return <>
      <Teleconsultation
        title={'Ma dernière consultation'}
        consultation={lastConsultation}
        summaryOnly={false}
      />
    </>
  }


  public renderProgramRecommendations() {
    const { profile } = this.props

    return <ProgramRecommendationCarousel profile={profile} />
  }

  public render() {
    const {
      profile: { firstName },
      unifiedCheckout: { lastFinishedConsultationFetchedOnce },
    } = this.props

    const titleSuffix = isEmpty(firstName) ? '' : ` ${capitalize(firstName)}`

    if (!lastFinishedConsultationFetchedOnce) {
      return <Loader />
    }

    return (
      <MainContainer title={`Bonjour${titleSuffix},`}>
        <div className="page home">
          <div className="home-container">
            <div className="infos-container">
              {this.renderTreatmentPlan()}
              {this.renderTeleconsultations()}
              {this.renderTreatments()}
              {this.renderProgramRecommendations()}
              {this.renderTherapyPrescription()}
              {this.renderPastConsulation()}
            </div>
          </div>
        </div>
      </MainContainer>
    )
  }
}

export default withRouter(Home)
