import React from 'react'
import cx from 'classnames'
import './styles.sass'

type Props = {
  children: React.ReactNode,
  text?: string,
  className?: string
}

const CardPill = ({ children, text, className }: Props) => {
  return (
    <div className={cx('d-flex flex-column price-card-offer-custom-padding block-content block-with-header-inside text-left', className)}>
      {text && (
        <div className="program-reduction-percent-wrapper">
          <h3 className="program-reduction-percent program-reduction-percent-position-clinic">{text}</h3>
        </div>
      )}
      {children}
    </div>
  )
}

export const CardBigPill = ({ children, text, className }: Props) => {
  return (
    <div className={cx('d-flex flex-column price-card-offer-custom-padding block-content block-with-header-inside text-left', className)}>
      {text && (
        <div className="program-reduction-percent-wrapper big-pill">
          <h3 className="program-reduction-percent program-reduction-percent-position-clinic">{text}</h3>
        </div>
      )}
      {children}
    </div>
  )
}

export default CardPill
