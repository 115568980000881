import { connect } from 'react-redux'
import {
  getOrderFromNumber,
  beginCheckout,
  createDelivery,
  getPaymentMethods,
  purchaseWith3dsChallenge,
  applyCoupon
} from 'actions'
import Checkout from 'page/Pages/Checkout'
import T from 'types'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'

const mapStateToProps = ({ storefront, auth, prescriptions: { collectionPrescription } }: T.Store) => {
  return {
    storefront,
    auth,
    collectionPrescription
  }
}

const mapDispatchToProps = (dispatch: Function) => ({
  purchaseWith3dsChallenge: (token: IToken, data: T.PaymentPayload, onSuccess: () => void) =>
    dispatch(purchaseWith3dsChallenge(token, data, onSuccess)),
  beginCheckout: (variants: T.CheckoutVariantChoice[], onSuccess: Function) =>
    dispatch(beginCheckout(variants, onSuccess)),
  createDelivery: (data: T.Forms.ShippingAddress, onSuccess: Function) =>
    dispatch(createDelivery(data, onSuccess)),
  getPaymentMethods: () => dispatch(getPaymentMethods()),
  getOrderFromNumber: (orderNumber: string) => dispatch(getOrderFromNumber(orderNumber)),
  applyCoupon: (coupon: string, orderToken: string) => dispatch(applyCoupon(coupon, orderToken))
})

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
