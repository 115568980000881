import { Block, SvgIcon } from 'components'
import { getIcon } from 'assets/icons'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import moment from 'constants/moment'
import { I18n } from 'react-redux-i18n'
import tracker from 'utils/trackers'
import './styles.sass'
import {
  get,
  isUndefined,
  isEmpty,
  capitalize,
  find
} from 'lodash'
import T from 'types'
import { MOUNJARO_MOLECULE, SAXENDA_MOLECULE, WEGOVY_MOLECULE } from '../../constants/prescriptions';
import { History } from 'history'
import SaxendaProgram from './BodyResetPrograms/SaxendaProgram'
import WegovyProgram from './BodyResetPrograms/WegovyProgram'
import MounjaroProgram from './BodyResetPrograms/MounjaroProgram';

type IProps = {
  profile: T.Profile
  drugsPrescriptions: T.PrescriptionV2[]
  phytoPrescriptions: T.PrescriptionV2[]
  collectionPrescription: T.Prescription[]
  programs: any
  onDrugsClick: (p: T.Prescription) => void
  onPhytoClick: (p: T.Prescription) => void
  history: History<any>
  setFocusedPrescription: (p: T.Prescription) => void
}

const programsPathMap = {
  time: "/programme-time",
  master: "/programme-master",
  sommeil: "/programme-sommeil",
  vaginisme: "/programme-vaginisme",
}

const moleculeTitle = (item: any, itemType: string): string => {
  const name = itemType === 'phytotherapy' ?
    I18n.t(`prescriptions.productsInformation.${get(item, 'molecule')}.name`) : capitalize(I18n.t(`molecule.${get(item, 'molecule')}`)) + ", " + I18n.t(`grammage.${get(item, 'grammage')}`)

  return name
}

const TreatmentPlan: FC<IProps> = (props: IProps) => {
  const {
    drugsPrescriptions,
    phytoPrescriptions,
    programs,
    collectionPrescription,
    onDrugsClick,
    onPhytoClick,
    profile,
    history,
    setFocusedPrescription
  } = props

  const handlePrescriptionClick = (pres: T.PrescriptionV2) => {
    const prescription = find(collectionPrescription, (p: T.Prescription) => p.id === pres.id)
    if (!isUndefined(prescription)) {
      get(pres, 'prescriptionType') === 'phytotherapy' ? onPhytoClick(prescription) : onDrugsClick(prescription)
    }
  }

  const handleProgramClick = (program: T.Program) => {
    const programPurchased: boolean = get(profile, `has${capitalize(program.shortName)}Program`, false)
    tracker.trackProgramPageClicked(program.slug, programPurchased, "treatment_plan")
  }

  const saxendaPrescription = drugsPrescriptions
    .find(pres => pres.prescriptionItems.some(item => item.molecule === SAXENDA_MOLECULE))

  const wegovyPrescription = drugsPrescriptions
    .find(pres => pres.prescriptionItems.some(item => item.molecule === WEGOVY_MOLECULE))

  const mounjaroPrescription = drugsPrescriptions
    .find(pres => pres.prescriptionItems.some(item => item.molecule === MOUNJARO_MOLECULE))

  // ugly hack to get the doctor name because in PrescriptionV2 doctor is typed as a string but it's an object
  const saxendaDoctor: any = saxendaPrescription && saxendaPrescription.doctor
  const wegovyDoctor: any = wegovyPrescription && wegovyPrescription.doctor
  const mounjaroDoctor: any = mounjaroPrescription && mounjaroPrescription.doctor

  let count = 0

  if (!isEmpty(drugsPrescriptions)) { count++ }
  if (!isEmpty(phytoPrescriptions)) { count++ }
  if (!isEmpty(programs)) { count++ }

  if (count === 0) {
    return null
  }

  const counterText = count > 1 ? `(${count} traitements prescrits)` : '(1 traitement prescrit)'

  return (
    <>
      {saxendaPrescription && !wegovyPrescription && !profile.bodyResetProgramLastPurchasedAt && (
        <>
          <div className="d-flex">
            <SvgIcon classname="icon-40px mr-2" icon={getIcon('clinic.doctor')}/>
            <h3>Le {saxendaDoctor.formattedName} vous a prescrit du <b>Saxenda®</b></h3>
          </div>
          <SaxendaProgram handleOrderPrescription={() => handlePrescriptionClick(saxendaPrescription)} />
        </>
      )}
      {wegovyPrescription && !profile.bodyResetProgramLastPurchasedAt && (
        <>
          <div className="d-flex">
            <SvgIcon classname="icon-40px mr-2" icon={getIcon('clinic.doctor')}/>
            <h3>Le {wegovyDoctor.formattedName} vous a prescrit du <b>Wegovy®</b></h3>
          </div>
          <WegovyProgram handleOrderPrescription={() => handlePrescriptionClick(wegovyPrescription)} />
        </>
      )}
      {mounjaroPrescription && !profile.bodyResetProgramLastPurchasedAt && (
        <>
          <div className="d-flex">
            <SvgIcon classname="icon-40px mr-2" icon={getIcon('clinic.doctor')}/>
            <h3>Le {mounjaroDoctor.formattedName} vous a prescrit du <b>Mounjaro®</b></h3>
          </div>
          <MounjaroProgram handleOrderPrescription={() => handlePrescriptionClick(mounjaroPrescription)} />
        </>
      )}
      <div className="d-flex mb-3">
        <SvgIcon classname="icon" icon={getIcon('clinic.checklist')} />
        <div className="d-flex flex-column">
          <h3>Mon plan de traitement</h3>
          <div>{counterText}</div>
        </div>
      </div>
      <Block classname="d-flex flex-column">
        {!isEmpty(drugsPrescriptions) && (
          <div className="treatment-recommendation">
            <div className="treatment-recommendation-title">
              <SvgIcon classname="icon mr-2" icon={getIcon('clinic.pill')} />
              <div>Mes médicaments</div>
            </div>
            {drugsPrescriptions.map((drug, i) =>
              <DrugPhytoPrescription
                key={`${drug.id}-${i}`}
                prescription={drug}
                onClick={() => handlePrescriptionClick(drug)}
              />)}
          </div>
        )}
        {!isEmpty(phytoPrescriptions) && (
          <div className="treatment-recommendation">
            <div className="treatment-recommendation-title">
              <SvgIcon classname="icon mr-2" icon={getIcon('phyto')} />
              <div>Mes phytothérapies</div>
            </div>
            {phytoPrescriptions.map((phyto, i) =>
              <DrugPhytoPrescription
                key={`${phyto.id}-${i}`}
                prescription={phyto}
                onClick={() => handlePrescriptionClick(phyto)}
              />)}
          </div>
        )}
        {!isEmpty(programs) && (
          <div className="treatment-recommendation">
            <div className="treatment-recommendation-title">
              <SvgIcon classname="icon mr-2" icon={getIcon('clinic.camera')} />
              <div>Mes programmes</div>
            </div>
            {programs.map((program: any, i: number) =>
              <ProgramPrescription
                key={`pp${i}`}
                program={program}
                onClick={() => handleProgramClick(program)}
              />)}
          </div>
        )}
      </Block>
    </>
  )
}

const DrugPhytoPrescription = (props: {
  onClick: () => void
  prescription: T.PrescriptionV2,
}) => {

  const { prescription, onClick } = props;

  if (isUndefined(prescription) || isUndefined(prescription.prescriptionItems)) {
    return null;
  }

  const { canBeReleased, expiredAt, prescriptionItems, prescriptionType, overTheCounter } = prescription
  const isWaitingToBeProcessed = prescription.state === "published"
  const expiredPrescription: boolean = moment().isAfter(moment(expiredAt))
  const disabledPrescription: boolean =
    isWaitingToBeProcessed || (!expiredPrescription && !canBeReleased)
  let prescriptionWarningWording = expiredPrescription
    ? 'Cette ordonnance est expirée'
    : isWaitingToBeProcessed
    ? 'Ordonnance en cours de traitement...'
    : !canBeReleased
    ? 'Commande maximale mensuelle atteinte'
    : null

  const showWarningText: boolean = prescriptionWarningWording !== null && !overTheCounter
  const ctaIcon: string = expiredPrescription && !overTheCounter ? 'startConsultation' : 'orderTruck'
  const ctaText: string = expiredPrescription && !overTheCounter
    ? 'Renouveler mon ordonnance'
    : 'Me faire livrer'

  return (
    <div className="treatment-prescription">
      <div className="treatment-prescription-items">
        <ul className="treatment-prescription-items-list">
          {prescriptionItems.map((item: any) => (
            <li>{moleculeTitle(item, prescriptionType)}</li>
          ))}
        </ul>
        <ul className="treatment-prescription-items-large">
          {prescriptionItems.map((item: any) => (
            <li>
              <SvgIcon classname="icon" icon={getIcon('clinic.pillsBottle')} />
              <span>{moleculeTitle(item, prescriptionType)} :</span>
              <ul className="treatment-molecule-details">
                <li>{I18n.t(`dosage.${item.dosage}`)}</li>
              </ul>
            </li>
          ))}
        </ul>
        {showWarningText && (
          <div className="treatment-prescription-warning">
            <strong>{prescriptionWarningWording}</strong>
          </div>
        )}
      </div>
      <div role="button" className={"treatment-plan-btn" + (disabledPrescription && !overTheCounter ? " treatment-order-btn-disabled" : "")} onClick={onClick}>
        <SvgIcon classname="icon" icon={getIcon(`${ctaIcon}`)} />
        <span className="treatment-plan-btn-label" dangerouslySetInnerHTML={{ __html: ctaText }}></span>
      </div>
    </div>
  )
}

const ProgramPrescription = (props: {
  program: any,
  onClick: () => void
}) => {

  const { program, onClick } = props

  if (isUndefined(program)) {
    return null
  }

  const { shortName, slug } = program
  const programPath = get(programsPathMap, shortName)

  return (
    <div className="treatment-prescription">
      <div className="treatment-program">
        <div className="treatment-program-description">
          <span className="treatment-program-title">{shortName.toUpperCase()}</span> : {I18n.t(`unified-checkout.program.${slug}.treatmentPlan`)}
        </div>
      </div>
      <div role="button" className="treatment-plan-btn program-btn">
        <Link to={programPath} className="program-link" onClick={onClick}>
          <SvgIcon classname="icon" icon={getIcon('play')} />
          <span>Regarder</span>
        </Link>
      </div>
    </div>
  )
}

export default TreatmentPlan
