import { isUndefined } from 'lodash'
import charles_psycho_sexo from './charles_psycho_sexo.json'
import weight from './weight.json'
import sleep from './sleep.json'
import digestion from './digestion.json'
import libido from './libido.json'
import fertility from './fertility.json'
import hair from './hair.json'
import reach_orgasm from './reach_orgasm.json'
import pain from './pain.json'
import sexual_desire from './sexual_desire.json'
import couple_therapy from './couple_therapy.json'
import andropause from './andropause.json'
import erectile_dysfunction from './erectile_dysfunction.json'
import premature_ejaculation from './premature_ejaculation.json'
import lack_of_pleasure from './lack_of_pleasure.json'
import no_ejaculation from './no_ejaculation.json'
import other from './other.json'
import sexual_wellness from './sexual_wellness.json'
import penile_curvature from './penile_curvature.json'
import porn_addiction from './porn_addiction.json'
import mia_other from './mia_other.json'
import open_consultation from './open_consultation.json'
import charles_couple_therapy from './charles_couple_therapy.json'

const speToSurvey: {
  [key: string]: any
} = {
  charles_psycho_sexo,
  weight,
  sleep,
  digestion,
  libido,
  fertility,
  hair,
  reach_orgasm,
  pain,
  sexual_desire,
  couple_therapy,
  andropause,
  erectile_dysfunction,
  premature_ejaculation,
  lack_of_pleasure,
  no_ejaculation,
  other,
  sexual_wellness,
  penile_curvature,
  porn_addiction,
  mia_other,
  open_consultation,
  charles_couple_therapy,
}

const loadSpeSurveyFromCache = (spe: string | undefined) => {
  if (isUndefined(spe)) {
    return undefined
  }
  return speToSurvey[spe]
}

export default loadSpeSurveyFromCache
