import React from 'react'
import largeMounjaroGreen from '../../../assets/images/large-mounjaro-green.png'
import { Button } from 'components'
import { PrescriptionProgramCardPrimary } from '../PrescriptionProgram/PrescriptionProgramCard'
import { MOUNJARO_PRICE } from '../../../constants/prescriptions'
import { getIcon } from '../../../assets/icons'
import SvgIcon from '../../SvgIcon'

const checkMark = <SvgIcon classname="icon saxenda-card-grid-icon mr-0" icon={getIcon('greenRoundCheck')} />
const crossMark = <SvgIcon classname="icon mr-0" icon={getIcon('redRoundCross')} />

const bodyResetProgram = {
  title: 'Programme Body Reset',
  description: 'Vos médicaments livrés chez vous et votre accompagnement par un coach GLP-1',
  pillArgument: 'Recommandé par votre médecin',
  image: largeMounjaroGreen,
  alt: 'mounjaro-body-reset',
  argumentList: [
    { text: <span>Perte de poids <span className="small">(en moyenne sur une année)</span></span>, with_charles: <div className="big-green-text">20%</div>, with_pharmacist: <div>10%</div> },
    { text: 'Coach disponible au quotidien', with_charles: checkMark, with_pharmacist: crossMark },
    { text: 'Gestion des effets secondaires', with_charles: checkMark, with_pharmacist: crossMark },
    { text: 'Livraison des traitements sous 48h', with_charles: checkMark, with_pharmacist: crossMark },
    { text: 'Remboursement par la sécurité sociale', both: <div className="refund-text">Ce traitement n’est pas remboursé</div> },
    { text: <span>Prix pour 4 semaines <span className="small">(à partir de)</span></span>, both : <div className="font-weight-semi">{MOUNJARO_PRICE}€</div> },
    { text: 'Programme nutrition en vidéo', with_charles: checkMark, with_pharmacist: crossMark },
  ],
}

type PrescriptionProgramProps = {
  handleOrderPrescription: () => void,
}

const MounjaroProgram = ({ handleOrderPrescription }: PrescriptionProgramProps) => {
  return (
    <div className="prescription-program-wrapper">
      <BodyResetProgramCard handleOrderPrescription={handleOrderPrescription} />
    </div>
  )
}

type BodyResetProgramCardProps = {
  handleOrderPrescription: () => void
}

const BodyResetProgramCard = ({ handleOrderPrescription }: BodyResetProgramCardProps) => {
  const cta = (
    <Button
      onClick={handleOrderPrescription}
      variant="btn-primary"
      classname="w-100"
    >
      Je commande
    </Button>
  )

  return (
    <PrescriptionProgramCardPrimary
      cta={cta}
      {...bodyResetProgram}
    />
  )
}

export default MounjaroProgram
